<template>
  <div class="hello" style="width: 100%;height: 100%;">
    <div class="scroll-container" @mouseenter="stopScroll" @mouseleave="startScroll">
      <div class="scroll-content">
        <!-- 长图，可以是一个图片列表 -->
        <img v-for="(img, index) in images" :key="index" src="../assets/picture.jpg" alt="">
        <!-- 复制第一张图片放在末尾，以实现无缝连接 -->
        <img src="../assets/picture.jpg" alt="">
      </div>
    </div>
    <div class="btn_box">
      <div class="android_btn">
        <a :href="androidUrl" rel="noopener">
        <img class="btn_img" src="../assets/android.png">
      </a>
      </div>
      <div class="apple_btn">
      <a :href="appleUrl" rel="noopener">
        <img class="btn_img" src="../assets/apple.png">
      </a>
      </div>
      <div class="shouxin">
      <a href="" rel="noopener">
        IOS授信教程
      </a>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      images: ["../assets/picture.jpg","../assets/picture.jpg","../assets/picture.jpg","../assets/picture.jpg"],
      intervalId: null,
      scrollSpeed: 1, // 可以根据需要调整滚动速度
      locationUrl: "http://103.86.47.145:8082/common/download",
      inviteCode: '',
      androidUrl: '',
      appleUrl: '',
    };
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const inviteCode = params.get("inviteCode");
    this.inviteCode = inviteCode;
    this.androidUrl = this.locationUrl+"?type=1&inviteCode="+inviteCode;
    this.appleUrl = this.locationUrl+"?type=2&inviteCode="+inviteCode;

    this.startScroll();
  },
  beforeDestroy() {
    this.stopScroll();
  },
  methods: {
    startScroll() {
      if (this.intervalId) return;
      this.intervalId = setInterval(() => {
        const container = this.$el.querySelector('.scroll-container');
        container.scrollTop += this.scrollSpeed;
        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
          // 当滚动到底部时，重置滚动位置
          container.scrollTop = 0;
        }
      }, 50); // 50毫秒滚动一次
    },
    stopScroll() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
  text-decoration: none;
}
.scroll-container {
  overflow: hidden;
  height: 100%; /* 根据需要设置容器高度 */
  position: relative;
  z-index: -10;
}
 
.scroll-content {
  position: absolute;
  width: 100%;
  z-index: -10;
}
 
.scroll-content img {
  width: 100%;
  height: auto;
  z-index: -10;
}

.btn_box{
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  opacity: 0.5;
  height: 35%;
  z-index: 10;
  position: absolute;
  bottom: 0;
}
.android_btn{
  width: 100%;
  height: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.apple_btn{
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_img{
  height: 3rem;
  width: 11rem;
}
.shouxin{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: white;
  font-size: 0.8rem;
}
</style>
